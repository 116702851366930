let baseurl = 'http://182.72.203.245:1881'

let user = `${baseurl}/api/v1/user`

const ApiConfig = {
  //USER
  subscirbe: `${user}/userSubscribe`,
}

export default ApiConfig
