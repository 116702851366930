import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import HomeLayoutOther from "src/layouts/HomeLayoutOther";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/disclaimer",
    layout: HomeLayoutOther,
    component: lazy(() => import("src/views/pages/Disclaimer")),
  },
  {
    exact: true,
    path: "/terms-of-use",
    layout: HomeLayoutOther,
    component: lazy(() => import("src/views/pages/Terms")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },

  {
    component: () => <Redirect to="/404" />,
  },
];
