import React, { useState } from 'react'
import {
  Grid,
  Box,
  Link,
  Button,
  Container,
  Typography,
  makeStyles,
  FormControl,
} from '@material-ui/core'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'
import TextField from '@material-ui/core/TextField'
import { FaLinkedinIn } from 'react-icons/fa'
import { FaTelegramPlane } from 'react-icons/fa'
import { FaTelegram } from 'react-icons/fa'
import { FaFacebookF } from 'react-icons/fa'
import { AiOutlineGooglePlus } from 'react-icons/ai'
import { AiOutlineInstagram } from 'react-icons/ai'
import { AiOutlineYoutube } from 'react-icons/ai'
import TwitterIcon from '@material-ui/icons/Twitter'
import { AiOutlineTwitter } from 'react-icons/ai'
import axios from 'axios'
import ApiConfig from 'src/ApiConfig/ApiConfig'
import {} from 'react-feather'
import { toast } from 'react-toastify'
import { isValidationEmail } from 'src/utils'
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: 'relative',
    // backgroundImage: "url(./images/footer_banner.svg)",
    backgroundPosition: ' bottom left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingTop: '57px',
    '& h5': {
      fontSize: '20px',
      color: '#42E8E0',
    },
    '& p': {
      fontSize: '12px',
      color: '#fff',
      margin: '0',
      padding: '13px 0 10px',
    },
    '& ul': {
      paddingLeft: '0',
      listStyle: 'none',
      margin: '0',
    },
    // "& input": {
    //   color: "#fff",
    //   background: "#3E3E3E",
    //   borderRadius: "5px",
    //   "&::placeholder": {
    //     color: "#fff",
    //   },
    // },
    '& button': {
      margin: '0',
      minWidth: '100px',
      borderRadius: '5px',
      background: 'linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)',
      '&:hover': {
        backgroundImage:
          'linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)',
        boxSizing: ' border-box',
        border: ' 2px solid transparent',
        backgroundClip: 'padding-box, border-box',
        backgroundOrigin: 'padding-box, border-box',
      },
    },
    '& .footer_text': {
      '& a': {
        color: '#848484',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '33px',
        '&:hover': {
          color: '#fff',
          textDecoration: 'none',
        },
      },
    },
    '& .follow': {
      display: 'flex',
      '& a': {
        background: '#3C3C3C',
        color: '#fff',
        marginRight: '5px',
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          background:
            ' linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)',
        },
      },
    },
  },
}))

export default function Liquidity() {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const subscriberAPIhandler = (values) => {
    axios({
      method: 'POST',
      url: ApiConfig.subscirbe,
      data: {
        email: values.email,
      },
    })
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          toast.success('Email send successfuly!')
        } else {
          toast.error(res.data.responseMessage)
        }
      })
      .catch((err) => {
        console.log(err.message)
      })
  }
  const formInitialSchema = {
    emailId: '',
  }
  const formValidationSchema = yup.object().shape({
    emailId: yup
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Must be a valid email.',
      )
      .required('*Email is required.'),

    passWord: yup.string().required('*No password provided.'),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // ),
  })
  return (
    <Box className={classes.footerSection}>
      <Container
        maxWidth="lg"
        style={{ paddingBottom: '30px', borderBottom: '1px solid #656262' }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3}>
            <img alt="" src="images/logo.svg" width={180} />
            <Box align="left" mt={3} mb={3} className="follow">
              <Link
                href="https://www.facebook.com/DexDaddyOfficial"
                target="_blank"
              >
                <FaFacebookF />
              </Link>
              <Link
                href="https://www.instagram.com/dexdaddyofficial"
                target="_blank"
              >
                <AiOutlineInstagram />
              </Link>
              <Link href="https://twitter.com/DexDaddyDeFi" target="_blank">
                <AiOutlineTwitter />
              </Link>
              <Link href="https://t.me/DexDaddyOfficialGroup" target="_blank">
                <FaTelegramPlane />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCOtk9Ke9QYZn6tw9gVF3eVQ"
                target="_blank"
              >
                <AiOutlineYoutube />
              </Link>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} md={2} align="left">
            <Box class=" col-lg-4 col-md-4 col-sm-6 col-12">
              <Typography variant="h5">Services</Typography>
              <ul className="footer_text">
                <li>
                  <Link
                    href="https://launchpad.dexdaddy.com/#/app/dashboard"
                    target="_blank"
                  >
                    IDO Launchpad
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://farm.dexdaddy.com/#/app/dashboard"
                    target="_blank"
                  >
                    Dex
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://mint.dexdaddy.com/#/app/dashboard"
                    target="_blank"
                  >
                    Mint
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://sale.dexdaddy.com/#/app/dashboard"
                    target="_blank"
                  >
                    Sale Events{' '}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://farm.dexdaddy.com/#/app/dashboard"
                    target="_blank"
                  >
                    Yield Farming
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://marketplace.dexdaddy.com/#/app/dashboard"
                    target="_blank"
                  >
                    Marketplace
                  </Link>
                </li>
              </ul>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} md={2} align="left">
            <Box class=" col-lg-4 col-md-4 col-sm-6 col-12">
              <Typography variant="h5">About us </Typography>
              <ul class="footer_text">
                <li>
                  <Link
                    target="_blank"
                    href="https://dexdaddypublicdocuments.s3.ap-south-1.amazonaws.com/dexDaddyWP.pdf"
                  >
                    White Paper{' '}
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://dexdaddypublicdocuments.s3.ap-south-1.amazonaws.com/dexDaddyPPT.pdf"
                    target="_blank"
                  >
                    PPT{' '}
                  </Link>
                </li>
                <li>
                  <a href="/terms-of-use" style={{textDecoration: "none",}}>Terms of Use </a>
                </li>
                <li>
                  <a href="/disclaimer"style={{textDecoration: "none",}}> Disclaimer</a>
                </li>
              </ul>
            </Box>
          </Grid>

          <Grid item xs={6} sm={4} md={2} align="left">
            <Box class=" col-lg-4 col-md-4 col-sm-6 col-12">
              <Typography variant="h5">Support</Typography>

              <ul class="footer_text">
                <li>
                  <Link href="#">FAQs</Link>
                </li>
                <li>
                  <Link href="#">Support</Link>
                </li>
              </ul>
            </Box>
          </Grid>
          <Formik
            initialValues={formInitialSchema}
            validationSchema={formValidationSchema}
            onSubmit={(values) => subscriberAPIhandler(values)}
          >
            {() => (
              <Form>
                <FormControl className="preinput" style={{ width: '100%' }}>
                  <Grid item xs={12} sm={12} md={12} align="left">
                    <Typography variant="h5">Subscribe more info</Typography>
                    <Field
                      as={TextField}
                      style={{ marginTop: '18px' }}
                      id="outlined-basic"
                      placeholder="Enter your Email"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      name="emailId"
                      style={{ minWidth: '200px', marginTop: '15px' }}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                    <p
                      style={{
                        color: 'red',
                        width: '100px',
                      }}
                    >
                      <ErrorMessage name="emailId" component="div" />
                    </p>

                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      type="submit"
                    >
                      Subscribe
                    </Button>
                  </Grid>
                </FormControl>
              </Form>
            )}
          </Formik>
        </Grid>
      </Container>
      <p align="center">2022@ DexDaddy. All Right reserved</p>
    </Box>
  )
}
