import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { CgClose } from "react-icons/cg";
const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "15px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    margin: "0 7px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#42E8E0",
    },
    "&:hover": {
      color: "#42E8E0",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },

  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "6px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },

  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },

  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    "& button": {
      margin: "0",
      marginLeft: "15px",
      minWidth: "100px",
      borderRadius: "5px",
      background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
      "&:hover": {
        boxSizing: " border-box",
        border: " 2px solid transparent",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
        backgroundImage:
          "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
      },
    },
  },
}));

export default function Header({ buttonClick }) {
  const {
    menuButton,
    toolbar,
    menuul,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    mainHeader,
  } = useStyles();
  const history = useHistory();
  console.log(history.location);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ paddingLeft: "0px" }}
        >
          <Grid item xs={2}>
            {productLogo}
          </Grid>
          <Grid item xs={10} align="right">
            {menuText}
          </Grid>
        </Grid>
      </Toolbar>
      // </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <IconButton onClick={handleDrawerClose} className="closeIcons">
            <CgClose width={35} style={{ color: "#42e8e0" }} />
          </IconButton>
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.svg" alt="" />
            {menuText}
          </div>
        </Drawer>

        <div>{productLogo}</div>

        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#fff", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const productLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const menuText = (
    <nav>
      <ul class={menuul}>
        <li>
          {" "}
          <MenuItem className={menuButton} component={Link} to="/">Home</MenuItem>
        </li>
        <li>
          {" "}
          <MenuItem className={menuButton} component={Link} to="/">Marketplace</MenuItem>
        </li>
        <li>
          {" "}
          <MenuItem className={menuButton} component={Link} to="/">IDO Platform</MenuItem>
        </li>
        <li>
          {" "}
          <MenuItem className={menuButton} component={Link} to="/">Mint</MenuItem>
        </li>
        <li>
          {" "}
          <MenuItem className={menuButton} component={Link} to="/">Tokenomics</MenuItem>
        </li>
        <li>
          {" "}
          <MenuItem className={menuButton} component={Link} to="/">Roadmap</MenuItem>
        </li>
        <li>
         <a href="https://mint.dexdaddy.com/" target="_blank" style={{textDecoration:"none"}}>
         <Button variant="contained" size="large" color="secondary">
            Launch App
          </Button>
         </a>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <AppBar
        // position={history.location.pathname !== "/" ? "relative" : "absolute"}
        className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
        elevation={0}
        style={{
          background:" linear-gradient(  1deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 0%) 100%)",
          backdropFilter: "blur(20px)",
          webkitBackdropFilter:"blur(20px)",
        }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
